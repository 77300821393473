import React from "react";
import { motion } from "framer-motion";

// Define floating and diagonal animation variants
const floatingVariants = {
  float: {
    y: [0, -15, 0],
    x: [0, 10, 0],
    transition: {
      duration: 4,
      repeat: Infinity,
      ease: "easeInOut",
    },
  },
};

const diagonalAccentVariants = {
  hidden: { scale: "0%" }, // Start off-screen to the right
  visible: { scale: "100%", transition: { duration: 2, ease: "easeInOut",  type:"spring", stiffness:100 } }, // Slide into view
};

const contentVariants = {
  hidden: { opacity: 0, x: 50 },
  visible: { opacity: 1, x: 0, transition: { duration: 1, delay: 0.5 } },
};

const imageVariants = {
  hidden: { 
    y: "100%", 
    rotate: 30, 
    scale: 0.8,
    opacity: 0 
  },
  visible: { 
    y: 0, 
    rotate: 0, 
    scale: 1, 
    opacity: 1, 
    transition: { 
      duration: 1.2, 
      ease: [0.68, -0.55, 0.27, 1.55], // Bounce easing
      type: "spring",
      stiffness: 100,
      damping: 10
    } 
  }
};


const SoundDesignSection = ({ inView }) => {
  return (
    <motion.div
      className="flex flex-col lg:flex-row items-center w-full h-screen py-16 relative bg-[#fff152] overflow-hidden"
    >
      {/* Diagonal Accent */}
      <motion.div
        className="absolute top-0 right-0 w-full h-full bg-[#ff8b02] z-0"
        style={{
          clipPath: "polygon(50% 0px, 100% 0px, 100% 100%, 32.55% 100%)",
        }}
        initial="hidden"
        animate={inView ? "visible" : "hidden"} // Trigger animation when section is in view
        variants={diagonalAccentVariants}
      />

      {/* Floating Icons */}
      <motion.div
        className="absolute top-10 left-1/4 w-10 h-10 rounded-full bg-white z-20"
        variants={floatingVariants}
        animate="float"
      />
      <motion.div
        className="absolute bottom-16 right-1/3 w-12 h-12 rounded-full bg-[#ff8b02] z-20"
        variants={floatingVariants}
        animate="float"
        style={{ animationDelay: "1s" }}
      />
      <motion.div
        className="absolute bottom-10 left-1/5 w-8 h-8 rounded-full bg-white z-20"
        variants={floatingVariants}
        animate="float"
        style={{ animationDelay: "2s" }}
      />

      {/* Content Section */}
      <motion.div
        className="w-full max-w-md lg:w-1/2 z-10 text-right p-8 absolute right-0"
        initial="hidden"
        animate={inView ? "visible" : "hidden"} // Trigger animation when section is in view
        variants={contentVariants}
        whileHover={{ scale: 1.05 }}
      >
        <h2 className="text-3xl font-bold text-white">Sound Design</h2>
        <p className="text-white text-lg mb-6">
          Crafting immersive audio experiences that integrate sound effects, music, and dialogue.
        </p>
        <ul className="text-white space-y-8">
          <li>
            <span className="font-bold text-xl block">Sound Design & Recording</span>
            <span className="text-xl block">Creating high-quality soundscapes that bring your visuals to life.</span>
          </li>
          <li>
            <span className="font-bold text-xl block">Sound Mastering</span>
            <span className="text-xl block">Optimizing audio quality to meet the highest standards.</span>
          </li>
          <li>
            <span className="font-bold text-xl block">5.1 Dolby Atmos Mixing</span>
            <span className="text-xl block">Delivering cinematic sound.</span>
          </li>
          <li>
            <span className="font-bold text-xl block">Voice Over Recording</span>
            <span className="text-xl block">Tailored high-quality voice recording for your project’s needs.</span>
          </li>
        </ul>
      </motion.div>

      {/* Image Section */}
      <motion.div
        className="w-full lg:w-3/5 z-10 absolute bottom-0 left-0"
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        variants={imageVariants}
        whileHover={{
          scale: 1.1,
          rotate: 3, // Slight rotation for dynamic effect
          filter: "brightness(1.2) saturate(1.5) drop-shadow(0px 0px 15px #ff8b02)", // Creative glow effect with color
          transition: {
            duration: 0.4,
            ease: "easeInOut",
          }
        }}
        
        
      >
        <img
          src="/assets/SoundDesign_Element.png"
          alt="Sound Design"
          className="object-contain w-full h-full"
        />
      </motion.div>
    </motion.div>
  );
};

export default SoundDesignSection;
