import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

const WhyChooseUsSection = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;

  
  const circleVariants = {
    hidden: {
      opacity: 0,
      scale: 0.5,
      rotateY: 90,
    },
    visible: (i) => ({
      opacity: 1,
      scale: 1,
      rotateY: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 12,
        delay: i * 0.2,
      },
    }),
  };

  const textVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 10,
        delay: 0.5,
      },
    },
  };

  const imageVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.5, delay: 0.3 },
    },
  };

  const createFloatingAnimation = (index) => ({
    y: ["0px", `${-15 - index * 5}px`, `${15 + index * 5}px`, "0px"],
    x: [`${-5 - index * 2}px`, `${5 + index * 2}px`, `${-5 - index * 2}px`],
    rotate: [0, 15, -20, 0],
    scale: [1, 1.05, 0.95, 1],
    transition: {
      duration: 6 + index,
      repeat: Infinity,
      repeatType: "loop",
      ease: "easeInOut",
    },
  });

  const elements = [
    {
      src: "/assets/WhyChooseUs_Element2.png",
      alt: "Camera",
      className:
        "absolute top-[6rem] left-[42%] transform -translate-x-1/2 -translate-y-1/2 w-48 h-48 object-contain",
    },
    {
      src: "/assets/WhyChooseUs_Element1.png",
      alt: "VHS Tape",
      className: "absolute bottom-28 left-64 w-40 object-contain",
    },
    {
      src: "/assets/WhyChooseUs_Element3.png",
      alt: "Cassette Tape",
      className: "absolute bottom-[9.5rem] right-16 w-36 h-36 object-contain",
    },
  ];

  const randomValue = (min, max) => Math.random() * (max - min) + min;

  const backgroundCircleAnimation = {
    x: [
      `${randomValue(-100, 100)}vw`,
      `${randomValue(-50, 50)}vw`,
      `${randomValue(-75, 75)}vw`,
      `${randomValue(-100, 100)}vw`,
    ],
    y: [
      `${randomValue(-100, 100)}vh`,
      `${randomValue(-50, 50)}vh`,
      `${randomValue(-75, 75)}vh`,
      `${randomValue(-100, 100)}vh`,
    ],
    scale: [1, randomValue(0.8, 1.5), randomValue(0.8, 1.5), 1],
    rotate: [0, randomValue(0, 360), randomValue(0, 360), 0], // Adding slight rotation for more dynamic movement
    transition: {
      x: {
        duration: randomValue(10, 20), // Randomized duration for horizontal movement
        repeat: Infinity,
        ease: "easeInOut",
        repeatType: "mirror",
      },
      y: {
        duration: randomValue(8, 18), // Randomized duration for vertical movement
        repeat: Infinity,
        ease: "easeInOut",
        repeatType: "mirror",
      },
      scale: {
        duration: randomValue(5, 10), // Randomized duration for scaling
        repeat: Infinity,
        ease: "easeInOut",
        repeatType: "mirror",
      },
      rotate: {
        duration: randomValue(12, 20), // Adding random rotation animation duration
        repeat: Infinity,
        ease: "linear",
        repeatType: "mirror",
      },
    },
  };

  const circleData = [
    {
      title: "Expert Team",
      content:
        "Our crew, including post production supervisors, editors, sound engineers, and colorists, bring years of industry expertise to every project, ensuring flawless execution.",
      color: "#8d54e0",
      position: "top-10 left-40",
      size: "w-80 h-80",
    },
    {
      title: "State of the Art Technology",
      content:
        "Equipped with cutting edge equipment, ensuring that your project benefits from the latest advancements in editing, sound, and visual effects.",
      color: "#00ebc0",
      position: "-bottom-6 left-12",
      size: "w-64 h-64",
    },
    {
      title: "Global Reach, Consistent Quality",
      content:
        "With strategic partnerships around the world, we ensure that regardless of where your production is filmed, we deliver a consistent, top-tier experience that meets international standards.",
      color: "#f8f565",
      position:
        "top-[12rem] left-[26rem] transform -translate-x-1/2 -translate-y-1/2",
      size: "w-80 h-80",
    },
    {
      title: "Versatility",
      content:
        "Whether you're creating corporate videos, commercials, documentaries, or narrative films, we craft each project into a visually compelling narrative that aligns with your vision.",
      color: "#fe8c28",
      position: "top-10 left-[43rem]",
      size: "w-80 h-80",
    },
    {
      title: "All in One Solution",
      content:
        "From pre-production to post-production, INVICTUS offers a comprehensive suite of services, so you can trust us to handle every aspect of your project.",
      color: "#00ebc0",
      position: "-bottom-6 right-[2rem]",
      size: "w-64 h-64",
    },
  ];

  const MobileCircle = ({ circle, index }) => (
    <motion.div
      className="bg-white rounded-lg shadow-lg p-4 mb-4" style={{ backgroundColor: circle.color }}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.2 }}
    >
      <h3 className="text-lg font-bold mb-2" style={{color: circle.color === '#f8f565' || circle.color === '#00ebc0' ? 'black' : 'white' }}>
        {circle.title}
      </h3>
      <p className="text-sm text-gray-600" style={{color: circle.color === '#f8f565' || circle.color === '#00ebc0' ? 'black' : 'white' }}>{circle.content}</p>
    </motion.div>
  );

  return (
    <div className="relative bg-gradient-to-br from-purple-500 via-teal-200 to-teal-400 min-h-screen flex items-center justify-center p-4 overflow-hidden">
      {/* Dynamic Floating Background Circles */}
      <motion.div
        className="absolute w-10 h-10 bg-purple-300 rounded-full opacity-30 top-[-50px] left-[-50px]"
        animate={backgroundCircleAnimation}
      />
      <motion.div
        className="absolute w-14 h-1w-14 bg-teal-300 rounded-full opacity-20 bottom-[-100px] right-[-100px]"
        animate={backgroundCircleAnimation}
      />
      <motion.div
        className="absolute w-4 h-4 bg-yellow-300 rounded-full opacity-20 top-[100px] right-[100px]"
        animate={backgroundCircleAnimation}
      />

<div className={`max-w-6xl w-full relative ${isMobile ? 'py-8' : ''}`} style={{ height: isMobile ? 'auto' : "500px" }}>
        {isMobile ? (
          <div className="space-y-4">
            <motion.h2
              className="text-2xl font-bold text-center text-white mb-6"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
            >
              Why Choose Us
            </motion.h2>
            {circleData.map((circle, index) => (
              <MobileCircle key={circle.title} circle={circle} index={index} />
            ))}
          </div>
        ) : (
          <>
            {circleData.map((circle, index) => (
              <motion.div
                key={circle.title}
                className={`absolute ${circle.position} ${circle.size} shadow-2xl rounded-full p-6 flex flex-col items-center justify-center text-center overflow-hidden`}
                style={{ backgroundColor: circle.color, color: circle.color === '#f8f565' || circle.color === '#00ebc0' ? 'black' : 'white' }}
                variants={circleVariants}
                initial="hidden"
                animate="visible"
                custom={index}
                whileHover={{
                  scale: 1.05,
                  rotateY: [-5, 5],
                  rotateX: [-5, 5],
                  boxShadow: "0px 25px 50px rgba(0,0,0,0.3)",
                  transition: {
                    duration: 0.3,
                    yoyo: Infinity,
                    ease: "easeInOut"
                  }
                }}
                whileTap={{
                  scale: 0.95,
                  rotate: [-2, 2],
                  transition: { 
                    duration: 0.2, 
                    yoyo: Infinity 
                  }
                }}
              >
                <motion.h3 
                  className="text-xl font-bold mb-2" 
                  variants={textVariants}
                >
                  {circle.title}
                </motion.h3>
                <motion.p 
                  className="text-sm" 
                  variants={textVariants}
                >
                  {circle.content}
                </motion.p>
              </motion.div>
            ))}

            {elements.map((element, index) => (
              <motion.div 
                key={element.alt}
                className={element.className}
                variants={imageVariants}
                initial="hidden"
                animate="visible"
              >
                <motion.img 
                  src={element.src} 
                  alt={element.alt}
                  animate={createFloatingAnimation(index)}
                  whileHover={{ 
                    scale: 1.2, 
                    rotate: [0, -10, 10, -10, 0],
                    transition: { duration: 0.5 }
                  }}
                />
              </motion.div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default WhyChooseUsSection;