import React from 'react';
import ServicesSection from '../components/ServicesSection';

const ServicesPage = () => {
  return (
    <div>
      <ServicesSection />
    </div>
  );
};

export default ServicesPage;