import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { MapPin, Mail, Star } from 'lucide-react';

const ContactSection = () => {

  const containerVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut",
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };

  const floatingAnimation = {
    y: ["0%", "-5%", "0%"],
    transition: {
      duration: 5,
      repeat: Infinity,
      ease: "easeInOut",
    },
  };

  return (
    <section id="contact" className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-900 via-blue-600 to-blue-400 p-4">
      <motion.div
        className="max-w-3xl w-full bg-white bg-opacity-10 backdrop-blur-lg rounded-3xl p-8 md:p-12 shadow-2xl text-center"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.h2 
          className="text-4xl md:text-5xl font-bold mb-8 text-white"
          variants={itemVariants}
        >
          Let's Create Something Extraordinary
        </motion.h2>
        <motion.p 
          className="text-xl mb-12 text-blue-100"
          variants={itemVariants}
        >
          Our team of experts are ready to work with you tirelessly.<br />
          Let's make your next project extraordinary—together.
        </motion.p>
        <motion.div 
          className="space-y-6"
          variants={containerVariants}
        >
          <motion.div 
            className="flex items-center justify-center space-x-4"
            variants={itemVariants}
            whileHover={{ scale: 1.05 }}
            animate={floatingAnimation}
          >
            <MapPin className="w-8 h-8 text-blue-300" />
            <p className="text-blue-100 text-lg">5000 Thayer Center, STE C, Oakland MD 21550</p>
          </motion.div>
          <motion.div 
            className="flex items-center justify-center space-x-4"
            variants={itemVariants}
            whileHover={{ scale: 1.05 }}
            animate={floatingAnimation}
          >
            <Mail className="w-8 h-8 text-blue-300" />
            <p className="text-blue-100 text-lg">productions@invictus-corp.co</p>
          </motion.div>
        </motion.div>
        
      </motion.div>
    </section>
  );
};

export default ContactSection;