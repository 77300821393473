import React from 'react';
import ContactSection from '../components/ContactSection';

const ContactPage = () => {
  return (
    <div>
      <ContactSection />
    </div>
  );
};

export default ContactPage;