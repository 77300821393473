import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronDown, ChevronUp, Menu } from "lucide-react";
import EditorialSection from "./EditorialSection";
import SoundDesignSection from "./SoundDesignSection";
import ColorGradingSection from "./ColorGradingSection";
import VFXSection from "./VFXSection";

const fadeInVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeOut" } },
};

const navItemVariants = {
  hidden: { opacity: 0, x: -20 },
  visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
};

const sections = [
  { name: "Editorial", component: EditorialSection, color: "#FF6B6B" },
  { name: "Sound Design", component: SoundDesignSection, color: "#4ECDC4" },
  { name: "Color Grading", component: ColorGradingSection, color: "#45B7D1" },
  { name: "VFX", component: VFXSection, color: "#FFA07A" },
];

const ServicesSection = () => {
  const [activeSection, setActiveSection] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrollDirection, setScrollDirection] = useState(null);

  // Create separate refs for each section
  const [refEditorial, inViewEditorial] = useInView({ threshold: 0.5 });
  const [refSoundDesign, inViewSoundDesign] = useInView({ threshold: 0.5 });
  const [refColorGrading, inViewColorGrading] = useInView({ threshold: 0.5 });
  const [refVFX, inViewVFX] = useInView({ threshold: 0.5 });

  const sectionRefs = [refEditorial, refSoundDesign, refColorGrading, refVFX];
  const inViewStates = [
    inViewEditorial,
    inViewSoundDesign,
    inViewColorGrading,
    inViewVFX,
  ];

  useEffect(() => {
    const activeIndex = inViewStates.findIndex((inView) => inView);
    if (activeIndex !== -1) {
      setActiveSection(activeIndex);
    }
  }, [inViewEditorial, inViewSoundDesign, inViewColorGrading, inViewVFX]);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (direction !== scrollDirection) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };

    window.addEventListener("scroll", updateScrollDirection);
    return () => {
      window.removeEventListener("scroll", updateScrollDirection);
    };
  }, [scrollDirection]);

  const handleNavClick = (index) => {
    setActiveSection(index);
    const element = document.getElementById(`section-${index}`);
    element.scrollIntoView({ behavior: "smooth" });
    setIsMenuOpen(false);
  };

  const handleScroll = (direction) => {
    const newIndex =
      direction === "up"
        ? Math.max(0, activeSection - 1)
        : Math.min(sections.length - 1, activeSection + 1);
    handleNavClick(newIndex);
  };

  return (
    <div className="w-full h-screen overflow-y-scroll snap-y snap-mandatory relative">
      {/* Floating Menu Button */}
      {/* <motion.button
        className="fixed top-4 left-4 z-50 bg-white rounded-full p-2 shadow-lg"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <Menu size={24} />
      </motion.button> */}

      {/* Full-screen Navigation Menu */}
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            className="fixed inset-0 bg-black bg-opacity-90 z-40 flex items-center justify-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.nav
              className="text-white text-2xl"
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={{
                visible: { transition: { staggerChildren: 0.1 } },
                hidden: {
                  transition: { staggerChildren: 0.05, staggerDirection: -1 },
                },
              }}
            >
              {sections.map((section, index) => (
                <motion.button
                  key={section.name}
                  className="block mb-6 focus:outline-none"
                  onClick={() => handleNavClick(index)}
                  variants={navItemVariants}
                  whileHover={{ scale: 1.1, color: section.color }}
                >
                  {section.name}
                </motion.button>
              ))}
            </motion.nav>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Scroll Indicators */}
      <motion.div
        className="fixed bottom-8 left-1/2 transform -translate-x-1/2 z-30 flex space-x-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1 }}
      >
        <motion.button
          className="p-2 bg-white rounded-full shadow-lg"
          onClick={() => handleScroll("up")}
          whileHover={{ y: -2 }}
          whileTap={{ scale: 0.95 }}
          animate={{ opacity: activeSection > 0 ? 1 : 0.5 }}
        >
          <ChevronUp size={24} />
        </motion.button>
        <motion.button
          className="p-2 bg-white rounded-full shadow-lg"
          onClick={() => handleScroll("down")}
          whileHover={{ y: 2 }}
          whileTap={{ scale: 0.95 }}
          animate={{ opacity: activeSection < sections.length - 1 ? 1 : 0.5 }}
        >
          <ChevronDown size={24} />
        </motion.button>
      </motion.div>

      {/* Service Sections */}
      <AnimatePresence mode="wait">
        {sections.map((section, index) => {
          const SectionComponent = section.component; // Extract component
          return (
            <motion.div
              key={section.name}
              id={`section-${index}`}
              ref={sectionRefs[index]}
              className="snap-start h-screen w-full relative overflow-hidden"
              initial="hidden"
              animate={inViewStates[index] ? "visible" : "hidden"}
              exit="hidden"
              variants={fadeInVariants}
            >
              <motion.div
                className="absolute inset-0 z-0"
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.2 }}
                exit={{ opacity: 0 }}
                style={{ backgroundColor: section.color }}
              />
              {/* Pass inView as a prop to each section */}
              <SectionComponent inView={inViewStates[index]} />

              <motion.h2
                className="absolute top-4 right-4 text-4xl font-bold text-white mix-blend-difference"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5 }}
              >
                {/* {section.name} */}
              </motion.h2>
            </motion.div>
          );
        })}
      </AnimatePresence>
    </div>
  );
};

export default ServicesSection;
