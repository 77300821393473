import React from "react";
import { motion } from "framer-motion";

// Floating icon variants with slight rotation for a dynamic feel
const floatingVariants = {
  float: {
    y: [0, -15, 0],
    x: [0, 10, 0],
    rotate: [0, 5, -5, 0],
    transition: {
      duration: 5,
      repeat: Infinity,
      ease: "easeInOut",
    },
  },
};

// Diagonal accent slide-in animation
const diagonalAccentVariants = {
  hidden: { x: "100%" },
  visible: { x: 0, transition: { duration: 1, ease: "easeInOut" } },
};

// Image animation with scaling and rotation for a dynamic entrance
const imageVariants = {
  hidden: { y: "100%", scale: 0.9, rotate: 10 },
  visible: { y: 0, scale: 1, rotate: 0, transition: { duration: 1.2, ease: "easeInOut" } },
};

const VFXSection = ({ inView }) => {
  return (
    <div className="flex flex-col lg:flex-row items-center w-full h-screen py-16 relative bg-[#fff152] overflow-hidden">
      {/* Diagonal Accent */}
      <motion.div
        className="absolute top-0 right-0 w-full h-full bg-[#ff8b02] z-0"
        style={{
          clipPath: "polygon(52% 0px, 100% 0px, 100% 100%, 17% 100%)",
        }}
        initial="hidden"
        animate={inView ? "visible" : "hidden"} // Animate when section is in view
        variants={diagonalAccentVariants}
      />

      {/* Floating Icons */}
      <motion.div
        className="absolute top-20 left-1/4 w-10 h-10 rounded-full bg-white z-20"
        variants={floatingVariants}
        animate="float"
      />
      <motion.div
        className="absolute bottom-20 right-1/3 w-12 h-12 rounded-full bg-[#ff8b02] z-20"
        variants={floatingVariants}
        animate="float"
        style={{ animationDelay: "1s" }}
      />
      <motion.div
        className="absolute bottom-10 left-1/5 w-8 h-8 rounded-full bg-white z-20"
        variants={floatingVariants}
        animate="float"
        style={{ animationDelay: "2s" }}
      />

      {/* Content Section */}
      <motion.div
  className="w-full max-w-md lg:w-1/2 z-10 text-right p-8 absolute right-0"
  initial="hidden"
  animate={inView ? "visible" : "hidden"}
  variants={{
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0, transition: { staggerChildren: 0.2 } },
  }}
>
  {/* Animated Heading with color change and scale */}
  <motion.h2
    className="text-3xl font-bold text-white"
    initial={{ opacity: 0, y: -20 }}
    animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: -20 }}
    transition={{ duration: 0.8, ease: "easeInOut" }}
    whileHover={{ scale: 1.1,  transition: { duration: 0.3 } }} // Change color and scale
  >
    VFX (Visual Effects)
  </motion.h2>

  {/* Animated Paragraph with subtle scale and text-shadow */}
  <motion.p
    className="text-white text-lg mb-6"
    initial={{ opacity: 0, y: -20 }}
    animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: -20 }}
    transition={{ duration: 0.8, delay: 0.2, ease: "easeInOut" }}
    whileHover={{ scale: 1.05, textShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)" }} // Slight scale with shadow
  >
    Enhancing your story with cutting-edge special effects for a more immersive experience.
  </motion.p>

  {/* Animated List Items with underline hover effect */}
  <ul className="text-white space-y-8">
    <motion.li
      initial={{ opacity: 0, x: 20 }}
      animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 20 }}
      transition={{ duration: 0.8, delay: 0.4, ease: "easeInOut" }}
      whileHover={{ scale: 1.05,  color: "#0ce9be" }} // Underline and color change
    >
      <span className="font-bold text-xl block">Compositing & Matte Painting</span>
      <span className="text-xl block">Seamlessly blending multiple elements or creating entirely new environments.</span>
    </motion.li>

    <motion.li
      initial={{ opacity: 0, x: 20 }}
      animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 20 }}
      transition={{ duration: 0.8, delay: 0.6, ease: "easeInOut" }}
      whileHover={{ scale: 1.05,  color: "#0ce9be" }} // Underline and color change
    >
      <span className="font-bold text-xl block">CGI & 3D Modeling</span>
      <span className="text-xl block">Adding complex, lifelike, or fantastical elements to your visuals.</span>
    </motion.li>

    <motion.li
      initial={{ opacity: 0, x: 20 }}
      animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 20 }}
      transition={{ duration: 0.8, delay: 0.8, ease: "easeInOut" }}
      whileHover={{ scale: 1.05,  color: "#0ce9be" }} // Underline and color change
    >
      <span className="font-bold text-xl block">Motion Graphics & Animation</span>
      <span className="text-xl block">Creating dynamic visuals that enhance the storytelling experience.</span>
    </motion.li>

    <motion.li
      initial={{ opacity: 0, x: 20 }}
      animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 20 }}
      transition={{ duration: 0.8,   }}
      whileHover={{ scale: 1.05,  color: "#0ce9be" }} // Underline and color change
    >
      <span className="font-bold text-xl block">Poster Design</span>
      <span className="text-xl block">Visually compelling designs that add professional polish to your project.</span>
    </motion.li>
  </ul>
</motion.div>


      {/* Image Section */}
      <motion.div
        className="w-full lg:w-3/5 z-10 absolute bottom-0 left-0"
        initial="hidden"
        animate={inView ? "visible" : "hidden"} // Trigger animation based on inView
        variants={imageVariants}
        whileHover={{ scale: 1.1, rotate: -5 }} // Adding rotation and scaling on hover
      >
        <img
          src="/assets/VFX_Element.png"
          alt="VFX"
          className="object-contain w-full h-full"
        />
      </motion.div>
    </div>
  );
};

export default VFXSection;
