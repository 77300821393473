import React from 'react';
import AboutUsSection from '../components/AboutUsSection';

const AboutPage = () => {
  return (
    <div>
      <AboutUsSection />
    </div>
  );
};

export default AboutPage;