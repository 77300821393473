import React from "react";
import { motion } from "framer-motion";
import { Edit, Film, Scissors } from "lucide-react";

// Floating icon animation
const floatingVariants = {
  float: {
    y: [0, -20, 0],
    transition: {
      y: {
        duration: 4,
        repeat: Infinity,
        ease: "easeInOut",
      },
    },
  },
};

// General animation for icons and text elements
const iconVariants = {
  hidden: { opacity: 0, scale: 0 },
  visible: { opacity: 1, scale: 1, transition: { duration: 0.5, ease: "easeOut" } },
};

const EditorialSection = ({ inView }) => {
  return (
    <div className="flex flex-col lg:flex-row items-center w-full h-screen py-16 relative bg-[#8060e5] overflow-hidden">
      {/* Animated Background */}
      <motion.div
        className="absolute inset-0 z-0"
        initial={{ backgroundPosition: "0% 0%" }}
        animate={inView ? { backgroundPosition: "100% 100%" } : {}}
        transition={{ repeat: Infinity, duration: 20, ease: "linear" }}
        
      />

      {/* Diagonal Accent */}
      <motion.div
        className="absolute top-0 right-0 w-full h-full bg-[#0ce9be] z-0"
        style={{
          clipPath: "polygon(35% 0px, 0px 0px, 0% 100%, 50% 100%)",
        }}
        initial={{ x: "-100%" }}
        animate={inView ? { x: 0 } : { x: "-100%" }}
        transition={{ duration: 1, ease: "easeInOut" , type:"spring",  stiffness:'100' }}
      />

      {/* Floating Icons */}
      <motion.div
        className="absolute top-16 left-1/4 w-10 h-10 rounded-full bg-white z-10 flex items-center justify-center"
        variants={floatingVariants}
        animate="float"
      >
        <Edit className="text-[#8060e5]" size={24} />
      </motion.div>
      <motion.div
        className="absolute bottom-20 right-1/3 w-8 h-8 rounded-full bg-[#00BFFF] z-10 flex items-center justify-center"
        variants={floatingVariants}
        animate="float"
        style={{ animationDelay: "1s" }}
      >
        <Film className="text-white" size={20} />
      </motion.div>
      <motion.div
        className="absolute bottom-10 left-1/5 w-6 h-6 rounded-full bg-white z-10 flex items-center justify-center"
        variants={floatingVariants}
        animate="float"
        style={{ animationDelay: "2s" }}
      >
        <Scissors className="text-[#8060e5]" size={16} />
      </motion.div>

      {/* Content Section */}
      <motion.div
        className="w-full max-w-md lg:w-1/2 z-10 text-right p-8 absolute right-0"
        initial={{ opacity: 0, x: 50 }}
        animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 50 }}
        transition={{ duration: 1, delay: 0.5 }}
        whileHover={{ scale: 1.05 }}
      >
        <motion.h2
          className="text-3xl font-bold text-white"
          initial={{ y: -20, opacity: 0 }}
          animate={inView ? { y: 0, opacity: 1 } : { y: -20, opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          Editorial
        </motion.h2>
        <motion.p
          className="text-white text-lg mb-6"
          initial={{ y: -20, opacity: 0 }}
          animate={inView ? { y: 0, opacity: 1 } : { y: -20, opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          Our editorial services ensure that every moment in your story is expertly refined.
        </motion.p>
        <ul className="text-white space-y-8">
          {[
            { title: "Storyboarding and Script Supervision", description: "Ensuring your narrative flows seamlessly.", icon: Edit },
            { title: "Scene Selection and Sequencing", description: "Choosing the most impactful shots and arranging them logically.", icon: Film },
            { title: "Editing and Trimming", description: "Shaping concise and engaging stories.", icon: Scissors },
          ].map((item, index) => (
            <motion.li
              key={index}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={iconVariants}
              transition={{ duration: 0.5, delay: 0.5 + index * 0.2 }}
              whileHover={{ scale: 1.05, color: "#0ce9be" }}
            >
              <span className="font-bold text-xl flex items-center justify-end">
                {item.title}
              </span>
              <span className="text-xl block">{item.description}</span>
            </motion.li>
          ))}
        </ul>
      </motion.div>

      {/* Image Section */}
      <motion.div
        className="w-full lg:w-3/5 z-10 absolute bottom-0 left-0"
        initial={{ y: "100%" }}
        animate={inView ? { y: 0 } : { y: "100%" }}
        transition={{ duration: 1, ease: "easeInOut" }}
        whileHover={{
          scale: 1.1,
          rotate: 3, // Slight rotation for dynamic effect
          filter: "brightness(1.2) saturate(1.5) drop-shadow(0px 0px 15px #ff8b02)", // Creative glow effect with color
          transition: {
            duration: 0.4,
            ease: "easeInOut",
          }
        }}
      >
        <img
          src="/assets/Editorial_Element.png"
          alt="Editorial"
          className="object-contain w-full h-full"
        />
      </motion.div>
    </div>
  );
};

export default EditorialSection;
