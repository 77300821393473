import React from 'react'
import WhyChooseUsSection from '../components/WhyChooseUsSection'

function WhyChooseUsPage() {
  return (
    <div>
      <WhyChooseUsSection />
    </div>
  )
}

export default WhyChooseUsPage
