import React from "react";
import { motion } from "framer-motion";

// Floating icon variants with slight rotation for more dynamic movement
const floatingVariants = {
  float: {
    y: [0, -20, 0],
    x: [0, 15, 0],
    rotate: [0, 10, -10, 0], // Adding rotation to enhance floating effect
    transition: {
      duration: 5,
      repeat: Infinity,
      ease: "easeInOut",
    },
  },
};

// Diagonal accent slide-in animation
const diagonalAccentVariants = {
  hidden: { x: "100%" },
  visible: { x: 0, transition: { duration: 1, ease: "easeInOut" } },
};

// Image animation with rotation and scaling for a unique reveal
const imageVariants = {
  hidden: { y: "100%", rotate: 10, scale: 0.9 },
  visible: {
    y: 0,
    rotate: 0,
    scale: 1,
    transition: { duration: 1.2, ease: "easeInOut" },
  },
};

const ColorGradingSection = ({ inView }) => {
  return (
    <div className="flex flex-col lg:flex-row items-center w-full h-screen py-16 relative bg-[#0ce9be] overflow-hidden">
      {/* Diagonal Accent */}
      <motion.div
        className="absolute top-0 right-0 w-full h-full bg-[#8060e5] z-0"
        style={{
          clipPath: "polygon(20% 0px, 100% 0px, 100% 100%, 35% 100%)",
        }}
        initial="hidden"
        animate={inView ? "visible" : "hidden"} // Trigger animation based on inView
        variants={diagonalAccentVariants}
      />

      {/* Floating Icons */}
      <motion.div
        className="absolute top-16 left-1/3 w-10 h-10 rounded-full bg-white z-20"
        variants={floatingVariants}
        animate="float"
      />
      <motion.div
        className="absolute bottom-16 right-1/3 w-12 h-12 rounded-full bg-[#8060e5] z-20"
        variants={floatingVariants}
        animate="float"
        style={{ animationDelay: "1s" }}
      />
      <motion.div
        className="absolute bottom-10 left-1/5 w-8 h-8 rounded-full bg-white z-20"
        variants={floatingVariants}
        animate="float"
        style={{ animationDelay: "2s" }}
      />

      {/* Content Section */}
      <motion.div
        className="w-full max-w-md lg:w-1/2 z-10 text-right p-8 absolute right-0"
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        variants={{
          hidden: { opacity: 0, x: 50 },
          visible: { opacity: 1, x: 0, transition: { staggerChildren: 0.2 } },
        }}
      >
        {/* Animated Heading */}
        <motion.h2
          className="text-3xl font-bold text-white"
          initial={{ opacity: 0, y: -20 }}
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: -20 }}
          transition={{ duration: 0.8, ease: "easeInOut" }}
        >
          Color Grading
        </motion.h2>

        {/* Animated Paragraph */}
        <motion.p
          className="text-white text-lg mb-6"
          initial={{ opacity: 0, y: -20 }}
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: -20 }}
          transition={{ duration: 0.8, delay: 0.2, ease: "easeInOut" }}
        >
          Our professional colorists enhance the mood, tone, and atmosphere of
          your footage to bring out the true essence of your vision.
        </motion.p>

        {/* Animated List Items */}
        <ul className="text-white space-y-8">
          <motion.li
            initial={{ opacity: 0, x: 20 }}
            animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 20 }}
            transition={{ duration: 0.8, delay: 0.4, ease: "easeInOut" }}
          >
            <span className="font-bold text-xl block">
              Color Grading and Correction
            </span>
            <span className="text-xl block">
              Enhancing the visual appeal of your footage.
            </span>
          </motion.li>

          <motion.li
            initial={{ opacity: 0, x: 20 }}
            animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 20 }}
            transition={{ duration: 0.8, delay: 0.6, ease: "easeInOut" }}
          >
            <span className="font-bold text-xl block">Color Correction</span>
            <span className="text-xl block">
              Ensuring consistent and accurate visuals.
            </span>
          </motion.li>

          <motion.li
            initial={{ opacity: 0, x: 20 }}
            animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 20 }}
            transition={{ duration: 0.8, delay: 0.8, ease: "easeInOut" }}
          >
            <span className="font-bold text-xl block">
              Creative Color Grading
            </span>
            <span className="text-xl block">
              Adding a unique visual flair that aligns with your narrative
              style.
            </span>
          </motion.li>
        </ul>
      </motion.div>

      {/* Image Section */}
      <motion.div
        className="w-full lg:w-1/3 z-10 absolute bottom-0 left-40 drop-shadow-xl"
        initial="hidden"
        animate={inView ? "visible" : "hidden"} // Trigger animation based on inView
        variants={imageVariants}
        whileHover={{ scale: 1.1, rotate: -5 }} // Adding rotation and scaling on hover
      >
        <img
          src="/assets/ColorGrading_Element.png"
          alt="Color Grading"
          className="object-contain w-full h-full"
        />
      </motion.div>
    </div>
  );
};

export default ColorGradingSection;
