import React from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { FaVideo, FaFilm, FaCameraRetro } from "react-icons/fa";

const AboutUsSection = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  const randomFloat = {
    y: [-20, 20],
    x: [-10, 10],
    opacity: [1, 0.8, 1],
  };

  const floatingTransition = {
    duration: 5,
    repeat: Infinity,
    repeatType: "reverse",
    ease: "easeInOut",
  };

  const isMobile = window.innerWidth <= 768;

  return (
    <section id="about" className="relative w-full overflow-hidden bg-[#FFCB00]">
      {/* Background and diagonal cyan cut */}
      <motion.div
        className="absolute inset-0"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <motion.div
          className="absolute top-0 right-0 w-full h-full bg-[#00BFFF]"
          style={{
            clipPath: isMobile
              ? "polygon(0 80%, 100% 60%, 100% 100%, 0% 100%)"
              : "polygon(85% 0, 100% 0, 100% 100%, 60% 100%)",
          }}
          initial={{ y: "100%" }}
          animate={{ y: 0 }}
          transition={{ duration: 1, ease: "easeInOut" }}
        ></motion.div>
      </motion.div>

      {/* Content */}
      <div className={`relative  h-screen flex items-center justify-center z-30`}>
        <div className={`container  ${isMobile ? 'px-4' : 'mr-40 px-4'}`}>
          <motion.div
            ref={ref}
            className={`mx-auto text-center ${isMobile ? 'max-w-sm' : 'max-w-xl'}`}
            variants={containerVariants}
            initial="hidden"
            animate={controls}
          >
            <motion.h2
              className="text-4xl font-bold mb-6 text-white"
              variants={itemVariants}
            >
              About Us
            </motion.h2>
            <motion.p
              className="text-base mb-4 text-gray-900"
              variants={itemVariants}
            >
              At INVICTUS, beauty is at the core of everything we do. We believe
              that every project deserves an elegant touch that transforms the
              ordinary into the extraordinary.
            </motion.p>
            <motion.p
              className="text-base mb-4 text-gray-900"
              variants={itemVariants}
            >
              Whether it's the intricate details in a color grade, the seamless
              flow of a narrative edit, or the immersive clarity of sound design,
              our team strives to craft visual experiences that captivate and
              inspire.
            </motion.p>
            <motion.p
              className="text-base text-gray-900"
              variants={itemVariants}
            >
              From commercials and branded content to documentaries, music videos,
              and feature films, our expertise delivers exceptional results, no
              matter the scope or scale of the project.
            </motion.p>
          </motion.div>
        </div>
      </div>

      {/* Images */}
      {isMobile ? (
        <>
        <motion.div
          className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-1/2 max-w-xs"
          initial={{ y: "100%" }}
          animate={{ y: 0 }}
          transition={{ duration: 1, type: "spring", stiffness: 120 }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/About Us_Element1.png`}
            alt="Vintage film projector"
            className="w-full h-auto object-contain"
          />
        </motion.div>
        <motion.div
          className="absolute bottom-0 left-0 transform -translate-x-1/2 w-1/4 max-w-xs z-0"
          initial={{ y: "100%" }}
          animate={{ y: 0 }}
          transition={{ duration: 1, type: "spring", stiffness: 120 }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/About Us_Element2.png`}
            alt="Modern camera"
            className="w-full h-auto object-contain"
          />
        </motion.div>
        
        </>
      ) : (
        <>
          <motion.div
            className="absolute top-20 left-0 h-2/4 z-50"
            initial={{ x: "-100%" }}
            animate={{ x: 0 }}
            transition={{ duration: 1, type: "spring", stiffness: 120 }}
            whileHover={{
              scale: 1.3,
              rotate: 5,
              transition: { duration: 0.3 },
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/About Us_Element2.png`}
              alt="Modern camera"
              className="h-full w-full object-contain"
            />
          </motion.div>

          <motion.div
            className="absolute bottom-0 right-20 h-3/4 z-50"
            initial={{ y: "100%" }}
            animate={{ y: 0 }}
            transition={{ duration: 1, type: "spring", stiffness: 120 }}
            whileHover={{
              scale: 1.3,
              rotate: -5,
              transition: { duration: 0.3 },
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/About Us_Element1.png`}
              alt="Vintage film projector"
              className="h-full w-full object-contain"
            />
          </motion.div>
        </>
      )}

      {/* Floating film-related icons with random movement */}
      {!isMobile && (
        <>
          <motion.div
            className="absolute top-1/4 left-1/4 text-white text-4xl z-0"
            animate={randomFloat}
            transition={floatingTransition}
          >
            <FaFilm />
          </motion.div>

          <motion.div
            className="absolute bottom-1/4 right-1/3 text-[#FFCB00] text-3xl z-0"
            animate={randomFloat}
            transition={floatingTransition}
          >
            <FaCameraRetro />
          </motion.div>

          <motion.div
            className="absolute top-1/3 right-1/4 text-[#00BFFF] text-5xl z-0"
            animate={randomFloat}
            transition={floatingTransition}
          >
            <FaVideo />
          </motion.div>
        </>
      )}
    </section>
  );
};

export default AboutUsSection;