import React from 'react';

const HeroSection = () => {
  return (
    <section className="relative bg-gray-900 h-screen flex items-center justify-center overflow-hidden">
      {/* Background Video */}
      <video
        autoPlay
        loop
        muted
        className="absolute z-0 w-full h-full object-contain md:object-cover"
      >
        <source src="/assets/Header Video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </section>
  );
};

export default HeroSection;
